import Vue from 'vue';
import VueYouTubeEmbed from 'vue-youtube-embed';
import VModal from 'vue-js-modal';
import App from './App.vue';
import './main.css';
import messages from './messages';
import VShowSlide from 'v-show-slide';
import VueI18n from 'vue-i18n';
import { initializeApp } from 'firebase/app';

const firebaseConfig = {
  apiKey: 'AIzaSyDCFpH3PjbLRKl_YJhd_t86mf1yTJFacdw',
  authDomain: 'samplebox-cloud.firebaseapp.com',
  projectId: 'samplebox-cloud',
  storageBucket: 'samplebox-cloud.appspot.com',
  messagingSenderId: '107940357038',
  appId: '1:107940357038:web:c647d12b4210de2b1a9e3f',
  measurementId: 'G-WL41DXR2N9',
};

initializeApp(firebaseConfig);

Vue.use(VueYouTubeEmbed, { global: true, componentId: 'yt-video' });
Vue.use(VModal);
Vue.use(VShowSlide);
Vue.use(VueI18n);

const i18n = new VueI18n({
  locale: 'en',
  messages,
});

Vue.config.productionTip = false;

new Vue({
  i18n,
  render: (h) => h(App),
}).$mount('#app');
