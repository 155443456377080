<template>
  <div
    class="auth screen-height flex items-center flex-col justify-center px-4"
  >
    <div
      class="text-5xl text-center select-none font-bold"
      style="line-height: 1.2em"
    >
      {{ $t('appEmoji') }}<br />
    </div>

    <div class="mt-5 text-center">
      <div class="text-4xl font-bold title">
        {{ $t('logIn') }}
      </div>
      <div class="mt-3 text-base subtitle">{{ $t('enterEmailToBegin') }}</div>
    </div>
    <div class="h-2/5 w-full spacer"></div>
    <dd-form
      class="w-full text-center justify-center"
      :class="isProcessing ? 'dd-form--processing' : ''"
      :data="credentials"
      :views="customViews"
      :lang="{ save: $t('continueWithEmail') }"
      @submit="submit"
      :descriptions="currentDescriptions"
    ></dd-form>
    <div
      v-if="isStateLogin"
      class="mt-3 text-base underline cursor-pointer text-gray-400"
      @click="switchStateToReset"
    >
      {{ $t('forgotPassword') }}
    </div>
  </div>
</template>
<script>
import ddForm from 'vue-dd-form';
import trim from 'lodash/trim';
import toLower from 'lodash/toLower';
import customTextView from '@/components/ViewText';
import {
  getAuth,
  signInWithEmailAndPassword,
  createUserWithEmailAndPassword,
  sendPasswordResetEmail,
} from 'firebase/auth';
import { getFirestore, doc, getDoc } from 'firebase/firestore';

export default {
  // eslint-disable-next-line vue/no-unused-components
  components: { ddForm, customTextView },
  data() {
    return {
      linkSent: false,
      processing: false,
      credentials: {},
      states: {
        DEFAULT: 'default',
        LOGIN: 'login',
        SIGNUP: 'signup',
        RESET: 'reset',
      },
      currentState: 'default',
    };
  },
  methods: {
    trim,
    toLower,
    async submit({ data }) {
      // 1. email normalization
      data.email = toLower(trim(data.email));

      // 2. email validation
      const isEmailValid = this.validateEmail(data.email);
      if (isEmailValid === false) {
        this.errorFlash();
        this.setProcessing(false);
        return null;
      }

      // 3. action execution
      if (this.isStateLogin) this.logInWithEmail({ data });
      else if (this.isStateSignup) this.signUpWithEmail({ data });
      else if (this.isStateReset) this.resetPassword({ data });
      else this.continueWithEmail({ data });
    },
    async continueWithEmail({ data }) {
      if (this.isProcessing) return null;
      this.setProcessing();

      const snap = await getDoc(doc(getFirestore(), 'users', data.email));
      if (snap.exists()) {
        this.currentState = this.states.LOGIN;
        this.setSubmitButtonText(this.$t('logIn'));
      } else {
        this.currentState = this.states.SIGNUP;
        this.setSubmitButtonText(this.$t('signUp'));
      }

      this.setProcessing(false);
    },
    async logInWithEmail({ data }) {
      if (this.isProcessing) return null;
      this.setProcessing();

      // Password validation
      if (!data.password) {
        this.errorFlash(1);
        this.setProcessing(false);
        return null;
      }

      const auth = getAuth();
      await signInWithEmailAndPassword(auth, data.email, data.password).catch(
        () => this.errorFlash(1)
      );
      this.setProcessing(false);
    },
    async resetPassword({ data }) {
      if (this.isProcessing) return null;
      this.setProcessing();
      try {
        this.setProcessing(false);
        const auth = getAuth();
        await sendPasswordResetEmail(auth, data.email).catch();
        this.setHeaderTexts(
          this.$t('sentToEmail'),
          this.$t('pleaseFollowInstructions')
        );
        this.hideDivs(['.dd-form', '.spacer']);
      } catch (error) {
        return this.errorFlash();
      }
    },
    async signUpWithEmail({ data }) {
      if (this.isProcessing) return null;
      this.setProcessing();

      // START: Password validation
      const minPasswordLength = 8;
      if (!data.password) {
        this.errorFlash(1);
        this.setProcessing(false);
        return null;
      }
      if (data.password.length < minPasswordLength) {
        this.errorFlash(1, 'password');
        this.setProcessing(false);
        return null;
      }
      if (!data.passwordConfirm || data.password !== data.passwordConfirm) {
        this.errorFlash(2);
        this.setProcessing(false);
        return null;
      }
      // END: Password validation

      const auth = getAuth();
      await createUserWithEmailAndPassword(
        auth,
        data.email,
        data.password
      ).catch((error) => alert(error));
      this.setProcessing(false);
    },
    switchStateToReset() {
      this.currentState = this.states.RESET;
      this.setSubmitButtonText(this.$t('sendResetLink'));
    },
    setSubmitButtonText(text) {
      document.querySelector('.dd-form .button--submit').innerText = text;
    },
    setHeaderTexts(title, subtitle) {
      document.querySelector('.title').innerText = title;
      document.querySelector('.subtitle').innerText = subtitle;
    },
    hideDivs(divPaths) {
      divPaths.forEach((path) => {
        document.querySelector(path).style.display = 'none';
      });
    },
    setProcessing(val = true) {
      this.processing = val;
    },
    validateEmail(inputText) {
      const regex = /^([a-zA-Z0-9_.-])+@(([a-zA-Z0-9-])+\.)+([a-zA-Z0-9]{2,4})+$/; // prettier-ignore
      return regex.test(inputText);
    },
    errorFlash(index = 0, resetValue = null) {
      const errorInput = document.getElementsByTagName('input')[index];
      errorInput.style.borderColor = '#ef4444';
      errorInput.style.animation = 'shake 0.82s cubic-bezier(.36,.07,.19,.97) both'; // prettier-ignore
      if (resetValue) {
        errorInput.value = '';
        this.credentials[resetValue] = '';
      }
      setTimeout(() => {
        errorInput.style.borderColor = 'inherit';
        errorInput.style.animation = 'none';
      }, 1000);
    },
  },
  computed: {
    isProcessing() {
      return this.processing;
    },
    isStateDefault() {
      return this.currentState === this.states.DEFAULT;
    },
    isStateLogin() {
      return this.currentState === this.states.LOGIN;
    },
    isStateSignup() {
      return this.currentState === this.states.SIGNUP;
    },
    isStateReset() {
      return this.currentState === this.states.RESET;
    },
    customViews() {
      return { text: customTextView };
    },
    currentDescriptions() {
      return this.isStateLogin
        ? this.formDescriptionsLogin
        : this.isStateSignup
        ? this.formDescriptionsSignup
        : this.formDescriptionsDefault;
    },
    formDescriptionsDefault() {
      return {
        email: { view: 'text', label: this.$t('email'), name: 'email' },
      };
    },
    formDescriptionsSignup() {
      return {
        email: { view: 'text', label: this.$t('email'), name: 'email' },
        password: {
          view: 'text',
          label: this.$t('passwordWithNote'),
          type: 'password',
        },
        passwordConfirm: {
          view: 'text',
          label: this.$t('passwordConfirm'),
          type: 'password',
        },
      };
    },
    formDescriptionsLogin() {
      return {
        email: { view: 'text', label: this.$t('email'), name: 'email' },
        password: {
          view: 'text',
          label: this.$t('password'),
          type: 'password',
        },
      };
    },
  },
};
</script>
<style lang="scss">
.auth {
  @apply flex;

  /* === START: Form Styles === */
  .view--root {
    margin: 0 !important;
  }
  .button--submit {
    display: block !important;
    border-radius: 3px !important;
    margin: 5px auto 5px auto !important;
    width: 100% !important;
  }
  .input-text {
    height: 42px !important;
    max-height: 42px !important;
  }
  form {
    width: 100%;
  }
  .dd-form {
    overflow: visible !important;
  }

  .dd-form--processing .button--submit {
    opacity: 0.5 !important;
    background-color: #c2052e !important;
  }
  /* === END: Form Styles === */
}

@keyframes shake {
  10%,
  90% {
    transform: translate3d(-1px, 0, 0);
  }

  20%,
  80% {
    transform: translate3d(2px, 0, 0);
  }

  30%,
  50%,
  70% {
    transform: translate3d(-4px, 0, 0);
  }

  40%,
  60% {
    transform: translate3d(4px, 0, 0);
  }
}
</style>
