<template>
  <div class="input-text__wrapper">
    <div class="input-text__description" v-if="label">
      <label class="input-text__label" v-text="label"></label>
    </div>
    <div
      class="input-text__frame overflow-hidden flex transition-all"
      :class="{ 'input-text__frame--focus': focus }"
    >
      <input
        class="input-text"
        type="tel"
        :value="value.split(':')[0]"
        @focus="focus = true"
        @blur="focus = false"
        @input="updateFirst"
      />
      <div style="background: #202020; color: #a7a7a7">:</div>
      <input
        class="input-text"
        type="tel"
        @focus="focus = true"
        @blur="focus = false"
        :value="value.split(':')[1]"
        @input="updateSecond"
      />
    </div>
  </div>
</template>
<script>
export default {
  props: ['path', 'value', 'description'],
  data() {
    return {
      label: this.description.label,
      focus: false,
    };
  },
  components: {},
  methods: {
    updateFirst(e) {
      this.$emit('change', {
        path: this.path,
        value: `${e.target.value}:${this.value.split(':')[1]}`,
      });
    },
    updateSecond(e) {
      this.$emit('change', {
        path: this.path,
        value: `${this.value.split(':')[0]}:${e.target.value}`,
      });
    },
  },
};
</script>
<style lang="scss" rel="stylesheet/scss" scoped>
.input-text {
  outline: none;
  background-color: #202020;
  height: 40px;
  max-height: 40px;
  max-width: 49%;
  display: flex;
  flex-grow: 1;
  padding: 0 10px;
  font-family: inherit;
  border-radius: 0;
  transition: 0.3s;
  border: 0 !important;
  font-size: 16px;
  font-weight: 300;
  color: #a7a7a7;
  letter-spacing: 1px;
  text-align: center;

  &__wrapper {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    position: relative;
  }
  &__frame {
    border: 2px solid #202020;
    &--focus {
      border: 2px solid #a7a7a7;
    }
  }
  &__description {
    margin-bottom: 10px;
  }
  &__label {
    color: #a7a7a7;
    font-size: 16px;
    height: auto;
    font-weight: 300;
    letter-spacing: 0.15px;
    position: relative;
  }
}
</style>
