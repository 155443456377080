<template>
  <div class="input-text__wrapper">
    <div class="input-text__description" v-if="label && showLabel">
      <label class="input-text__label" v-text="label"></label>
    </div>
    <input
      class="input-text"
      :value="value"
      :name="name || ''"
      :type="type || ''"
      :placeholder="!showLabel ? label : ''"
      @input="update"
      @focus="updateFocus"
      @blur="updateFocus(false)"
    />
    <div
      v-if="suggestions && focus && !addingNew"
      class="absolute mt-16 shadow-md max-h-36 overflow-scroll"
      style="min-width: 130px; z-index: 99999; background: #2f2f2f;"
    >
      <div
        v-for="suggestion in filteredSuggestions"
        :key="suggestion"
        @click="update({ target: { value: suggestion } })"
        class="cursor-pointer hover:opacity-60 w-full px-5 py-2 transition-all"
      >
        {{ suggestion }}
      </div>
      <div
        v-if="!existsInSuggestions && !!value"
        @click="addingNew = true"
        class="cursor-pointer hover:opacity-60 w-full px-5 py-2 transition-all"
      >
        {{ `Add "${value}"` }}
      </div>
    </div>
  </div>
</template>
<script>
import trim from 'lodash/trim';
import toLower from 'lodash/toLower';

export default {
  props: ['path', 'value', 'description'],
  computed: {
    existsInSuggestions() {
      return this.suggestions.find((s) => {
        const value = toLower(trim(this.value));
        const suggestion = toLower(trim(s));
        return suggestion === value;
      });
    },
    filteredSuggestions() {
      return this.suggestions.filter((s) => {
        const value = toLower(trim(this.value));
        const suggestion = toLower(trim(s));
        return suggestion.includes(value) && suggestion !== value;
      });
    },
  },
  data() {
    return {
      focus: false,
      label: this.description.label,
      name: this.description.name,
      type: this.description.type,
      suggestions: this.description.suggestions,
      showLabel: this.description.showLabel,
      addingNew: false,
    };
  },
  components: {},
  methods: {
    updateFocus(v = true) {
      setTimeout(
        () => {
          this.focus = v;
        },
        v ? 0 : 100
      );
    },
    update(e) {
      this.addingNew = false;
      this.$emit('change', {
        path: this.path,
        value: e.target.value,
      });
    },
  },
};
</script>
<style lang="scss" rel="stylesheet/scss" scoped>
.input-text {
  outline: none;
  border: 2px solid #2f2f2f;
  background-color: #202020;
  height: 40px;
  max-height: 40px;
  display: flex;
  flex-grow: 1;
  padding: 0 10px;
  font-family: inherit;
  border-radius: 0;
  transition: 0.3s;
  font-size: 16px;
  font-weight: 300;
  color: #a7a7a7;
  letter-spacing: 1px;
  &:focus {
    border: 2px solid #a7a7a7 !important;
  }
  &__wrapper {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    position: relative;
  }
  &__description {
    margin-bottom: 10px;
  }
  &__label {
    color: #a7a7a7;
    font-size: 16px;
    height: auto;
    font-weight: 300;
    letter-spacing: 0.15px;
    position: relative;
  }
}
</style>
