<template>
  <div class="w-full relative screen-height overflow-visible">
    <!-- Toolbar -->
    <div
      :class="{ absolute: isLibraryEmpty }"
      class="w-full flex text-gray-400 py-3 px-2 border-b border-white border-opacity-10"
      @paste="removeFocus"
      id="toolbar"
    >
      <!-- Toolbar: Link paste input -->
      <input
        v-show="clipboardError || true"
        style="background: #090909"
        :class="{
          'w-full outline-none mr-5 text-white':
            clipboardError || !clipboardAllowed || true,
        }"
        :placeholder="$t('pasteLinkHere')"
        v-model="pastedText"
      />
      <!-- Toolbar: Filter button -->
      <div
        class="text-right cursor-pointer w-auto flex items-center hover:opacity-80 transition-all"
        :style="isFilterActive ? { color: '#c2052e', fontWeight: 'bold' } : {}"
        @click="openFilter"
      >
        {{ $t('filter') }}
      </div>
    </div>

    <!-- List -->
    <div v-if="!isLibraryEmpty" id="items">
      <!-- Item -->
      <div
        v-for="item in currentItems"
        :key="item.id"
        class="w-full py-3 px-2 border-b border-white border-opacity-10"
        :class="{
          'cursor-pointer': !isSelected(item.id),
          'bg-yellow-200': isClipboardItem(item.id),
        }"
        :style="isSelected(item.id) ? 'background: #202020' : ''"
        @click="isClipboardItem(item.id) ? addItem(item) : selectItem(item.id)"
      >
        <!-- Item: Wrapper  -->
        <div class="wrapper flex transition-all">
          <div
            v-if="!isSelected(item.id)"
            class="bg-black flex items-center"
            style="width: 60px; height: 60px"
          >
            <img :src="item.artwork" style="width: 60px" />
          </div>
          <div
            :class="{ 'px-2': !isSelected(item.id) }"
            :style="!isSelected(item.id) ? 'width: calc(100% - 60px)' : ''"
          >
            <div class="flex">
              <!-- Item: Title -->
              <p
                class="w-full cursor-pointer font-medium"
                :class="{ 'text-gray-900': isClipboardItem(item.id) }"
                @click.stop="
                  isClipboardItem(item.id)
                    ? addItem(item)
                    : isSelected(item.id)
                    ? unselectItem()
                    : selectItem(item.id)
                "
              >
                {{
                  isSelected(item.id)
                    ? item.title
                    : truncate(item.title, { length: 76 })
                }}
              </p>

              <!-- Item: Options dropdown  -->
              <DropdownMenu
                ref="dropdownMenu"
                v-if="isSelected(item.id)"
                :options="[
                  { text: $t('edit'), action: openEdit },
                  { text: $t('download'), action: startDownload },
                ]"
              />
            </div>

            <!-- Item: Clipboard download -->
            <div
              class="text-xs cursor-pointer mt-1"
              :class="{ 'text-gray-900': isClipboardItem(item.id) }"
              v-if="isClipboardItem(item.id)"
            >
              {{ $t('addFromClipboard') }}
            </div>

            <!-- Item: Add timestamps button -->
            <div
              class="text-xs cursor-pointer mt-1"
              v-if="!hasTimestamps(item) && !isClipboardItem(item.id)"
              @click="
                () => {
                  selectItem(item.id);
                  openEdit();
                }
              "
            >
              {{ $t('addTimestamps') }}
            </div>

            <!-- Item: Note -->
            <div
              class="text-xs text-gray-400"
              v-show="isSelected(item.id) && item.note"
            >
              {{ item.note }}
            </div>

            <!-- Item: Stamps -->
            <transition-group class="flex flex-wrap" name="list" tag="div">
              <div
                v-for="(stamp, i) in isSelected(item.id)
                  ? item.timestamps
                  : uniqBy(item.timestamps, 'type')"
                :key="
                  isSelected(item.id)
                    ? `${i}:${stamp.time}`
                    : `${i}:${stamp.type || $t('missingType')}`
                "
                class="mr-1 px-2 text-white rounded-sm flex items-center justify-center"
                :class="
                  isSelected(item.id)
                    ? ['text-sm', 'mt-3']
                    : ['text-xs', 'mt-1']
                "
                :style="{
                  backgroundColor: getStampColor(stamp).main,
                  color: 'white',
                  fontWeight: 'bold',
                  paddingTop: isSelected(item.id) ? '5px' : '2px',
                  paddingBottom: isSelected(item.id) ? '5px' : '2px',
                  pointerEvents: isSelected(item.id) ? 'inherit' : 'none',
                  cursor: isSelected(item.id) ? 'pointer' : 'inherit',
                }"
                @click="jumpTo(stamp.time)"
              >
                <div v-if="isSelected(item.id)" class="px-2">
                  {{ stamp.time }}
                </div>
                <strong v-else>{{ stamp.type || $t('missingType') }}</strong>
              </div>
            </transition-group>

            <!-- Item: Video -->
            <yt-video
              v-if="isSelected(item.id)"
              :player-width="getItemWrapperWidth()"
              :player-vars="{
                start: getFirstTimestampTime(item),
                autoplay: hasTimestamps(item) ? 1 : 0,
              }"
              :video-id="getYoutubeVideoId(item.link)"
              @ready="videoReady"
              class="mt-4"
            ></yt-video>
          </div>
        </div>

        <!-- Item: Close button  -->
        <div
          v-if="isSelected(item.id)"
          @click.stop="unselectItem"
          class="w-full flex justify-center pt-6 pb-1"
        >
          <div
            class="text-sm cursor-pointer select-none"
            style="transform: rotate(270deg)"
          >
            {{ $t('close') }}
          </div>
        </div>
      </div>
    </div>

    <!-- Empty library alert -->
    <div
      v-if="isLibraryEmpty"
      class="flex flex-col items-center justify-center screen-height"
    >
      <div
        class="text-2xl font-bold text-center"
        v-html="isFilterActive ? $t('noResultsForFilter') : $t('emptyLibrary')"
      ></div>
      <div class="text-sm pt-2">
        {{
          isFilterActive
            ? ''
            : clipboardError
            ? $t('pasteLinkAbove')
            : $t('pasteLinkToBegin')
        }}
      </div>
    </div>

    <div v-show="!isLibraryEmpty && !isItemSelected" id="spacer"></div>

    <!-- Footer -->
    <div
      class="flex justify-between w-full absolute py-3 mb-6 px-2 text-gray-400"
      id="footer"
    >
      <!-- Sign out button -->
      <div
        v-show="!isItemSelected"
        class="text-right cursor-pointer hover:opacity-80 transition-all"
        @click="logOut"
      >
        {{ $t('logOut') }}
      </div>
      <div>
        {{ currentItems.length }}
        {{ $t(currentItems.length === 1 ? 'item' : 'items') }}
      </div>
    </div>

    <template>
      <modal
        name="edit-modal"
        id="edit-modal"
        :height="'450px'"
        :scrollable="true"
        :focusTrap="false"
      >
        <div
          slot="top-right"
          class="flex justify-center items-center"
          @click.stop="$modal.hide('edit-modal')"
        >
          <button
            class="button button--submit"
            @click.stop="$refs.ddForm.submit()"
          >
            {{ $t('save') }}
          </button>
          <button
            class="button mt-4 ml-10 text-red-500 hover:text-red-600 underline font-normal transition-all"
            @click="deleteItem"
          >
            {{ $t('delete') }}
          </button>
        </div>
        <dd-form
          ref="ddForm"
          class="pb-10"
          :data="{ ...selectedItem }"
          :descriptions="itemDescriptions"
          :views="customViews"
          @submit="updateItem"
        ></dd-form>
      </modal>
    </template>
    <template>
      <modal
        name="filter-modal"
        :height="'320px'"
        :style="{ top: '120px' }"
        :scrollable="true"
        :focusTrap="false"
      >
        <div class="text-center flex items-center justify-center h-full w-full">
          <dd-form
            ref="ddForm"
            class="pb-10"
            :data="filterModel"
            :descriptions="{
              onlyStampTypes: {
                view: 'checkbox',
                label: $t('showContaining'),
                options: [...stampTypes],
              },
            }"
            :views="customViews"
            @submit="submitFilter"
          ></dd-form>
        </div>
        <div
          slot="top-right"
          class="flex justify-center items-center"
          @click.stop="$modal.hide('filter-modal')"
        >
          <button
            class="button button--submit"
            @click.stop="$refs.ddForm.submit()"
          >
            {{ $t('update') }}
          </button>
          <button
            class="button mt-4 ml-10 text-white hover:opacity-80 transition-all font-normal"
            @click="clearFilter"
          >
            {{ $t('clearFilter') }}
          </button>
        </div>
      </modal>
    </template>
    <template>
      <modal
        name="download-modal"
        :height="'320px'"
        :style="{ top: '120px' }"
        :scrollable="true"
        :focusTrap="false"
      >
        <div class="text-center flex items-center justify-center h-full w-full">
          <div>{{ $t('pleaseWait') }}</div>
        </div>
      </modal>
    </template>
  </div>
</template>
<script>
import {
  stampColors,
  clipboardItemReservedId,
  defaultStampTypes,
  defaultMaxItems,
} from '../config';
import { getAuth, signOut } from 'firebase/auth';
import {
  getFirestore,
  doc,
  updateDoc,
  deleteDoc,
  addDoc,
  collection,
} from 'firebase/firestore';
import ddForm from 'vue-dd-form';
import axios from 'axios';
import truncate from 'lodash/truncate';
import uniqBy from 'lodash/uniqBy';
import customTextView from '@/components/ViewText';
import customTimeView from '@/components/ViewTime';
import DropdownMenu from '@/components/DropdownMenu';

export default {
  props: ['items', 'profile', 'clipboardAllowed'],
  data() {
    return {
      customViews: {
        text: customTextView,
        time: customTimeView,
      },
      clipboardItem: null,
      selectedItem: {},
      videoPlayer: null,
      clipboardInterval: null,
      clipboardText: null,
      clipboardError: !this.clipboardAllowed,
      clipboardYoutubeId: null,
      clipboardHistory: [],
      pastedText: null,
      currentFilter: {},
      filterModel: {},
    };
  },
  watch: {
    clipboardYoutubeId(val) {
      if (val) this.fetchClipboardItemInfo();
    },
  },
  computed: {
    isLibraryEmpty() {
      return !this.currentItems.length;
    },
    isItemSelected() {
      return !!this.selectedItem.id;
    },
    currentItems() {
      const currentItems = this.newClipboardItem
        ? [this.newClipboardItem, ...this.items]
        : this.items;
      let filteredItems = [...currentItems];

      if (this.isFilterActive) {
        // Filter #1: onlyStampTypes
        filteredItems = filteredItems.filter((item) => {
          const itemStampTypes = item.timestamps.map((stamp) => stamp.type);
          const hasSomeStampMatch = this.currentFilter.onlyStampTypes.some(
            (stampType) => {
              return itemStampTypes.includes(stampType);
            }
          );
          return hasSomeStampMatch;
        });
      }
      // NOTE: Uncomment below for export
      // console.log(JSON.stringify(filteredItems));
      return filteredItems;
    },
    isFilterActive() {
      return (
        !!Object.keys(this.currentFilter).length &&
        // Special rule #1: onlyStampTypes
        this.currentFilter.onlyStampTypes.length > 0
      );
    },
    newClipboardItem() {
      if (!this.clipboardItem) return null;

      const newLink = `https://www.youtube.com/watch?v=${this.clipboardYoutubeId}`;
      const foundDuplicate = this.items.find((item) => this.getYoutubeVideoId(item.link) === this.getYoutubeVideoId(newLink)); // prettier-ignore
      const reachedLimit = this.items.length >= defaultMaxItems;
      if (reachedLimit) {
        alert(`${this.$t('limitOf')} ${defaultMaxItems} ${this.$t('wasReachedContactUs')}`); // prettier-ignore
        return null;
      } else if (foundDuplicate) {
        return null;
      } else {
        return this.clipboardItem;
      }
    },
    itemDescriptions() {
      return {
        timestamps: {
          view: 'collection',
          label: 'Timestamps',
          class: 'p-6 bg-gray-200 timestamp__wrapper',
          draggable: false,
        },
        'timestamps[*]': {
          view: 'group',
          class: 'timestamp',
          append: { time: ':' },
        },
        'timestamps[*].time': {
          view: 'time',
          label: 'Time',
          wrapper: 'flex',
          class: 'time z-50',
        },
        'timestamps[*].type': {
          view: 'text',
          label: 'Type',
          class: 'w-full z-50 type',
          wrapper: 'flex',
          showLabel: true,
          suggestions: this.stampTypes,
        },
        title: { view: 'text', label: 'Title' },
        link: { view: 'text', label: 'Link' },
        note: { view: 'text', label: 'Note' },
      };
    },
    stampTypes() {
      const foundStamps = [];
      [...this.items].reverse().forEach((item) => {
        foundStamps.push(...item.timestamps.map((timestamp) => timestamp.type));
      });
      return uniqBy([...foundStamps, ...defaultStampTypes]).filter((s) => !!s);
    },
  },
  components: {
    ddForm,
    DropdownMenu,
    // eslint-disable-next-line vue/no-unused-components
    customTextView,
    // eslint-disable-next-line vue/no-unused-components
    customTimeView,
  },
  updated() {
    this.calculateLogOutOffset();
  },
  mounted() {
    this.calculateLogOutOffset();
    this.watchClipboardData();
    this.registerKeyboardEvents();
  },
  methods: {
    uniqBy,
    truncate,
    calculateLogOutOffset() {
      const screenHeight = document.body.clientHeight;
      const pasteInputHeight = this.getElement('toolbar').clientHeight;
      const logOutHeight = this.getElement('footer').clientHeight;
      const libraryItemsHeight = this.getElement('items').clientHeight;
      const spacerElement = this.getElement('spacer');
      const addition = 50;
      const newSpacerHeight =
        screenHeight - pasteInputHeight - logOutHeight - libraryItemsHeight;
      spacerElement.style.height = `${
        newSpacerHeight > 0 ? newSpacerHeight + addition : 0
      }px`;
    },
    logOut() {
      signOut(getAuth());
    },
    getElement(id) {
      return document.getElementById(id) || { clientHeight: 0 };
    },
    getFirstTimestampTime(item) {
      return item.timestamps[0]
        ? this.getSecondsFromTimeString(item.timestamps[0].time)
        : 0;
    },
    removeFocus() {
      setTimeout(() => document.activeElement.blur(), 50);
    },
    registerKeyboardEvents() {
      setTimeout(() => {
        document.onkeydown = (evt) => {
          evt = evt || window.event;
          const isEditOpen = document.getElementById('edit-modal');

          // Key: ESC
          if (evt.keyCode == 27 && !isEditOpen) {
            this.unselectItem();
          }
          // Key: ENTER
          if (evt.keyCode == 13 && this.newClipboardItem && !isEditOpen) {
            this.addItem(this.newClipboardItem);
          }

          // Key: SPACE
          if (evt.keyCode == 32 && !isEditOpen && this.isItemSelected) {
            evt.preventDefault();
            this.playPause();
          }
        };
      }, 50);
    },
    getYoutubeVideoId(link) {
      let regex = /(youtu.*be.*)\/(watch\?v=|embed\/|v|shorts|)(.*?((?=[&#?])|$))/gm; // prettier-ignore
      const match = regex.exec(link);
      return match && match[3] ? match[3] : null;
    },
    getSecondsFromTimeString(time) {
      const parts = time.split(':');
      const minutes = Number(parts[0]) || 0;
      const seconds = Number(parts[1]) || 0;
      return minutes * 60 + seconds;
    },
    playPause() {
      const isPaused = this.videoPlayer.getPlayerState() === 2;
      if (isPaused) this.videoPlayer.playVideo();
      else this.videoPlayer.pauseVideo();
    },
    jumpTo(time) {
      this.videoPlayer.seekTo(this.getSecondsFromTimeString(time));
      this.videoPlayer.playVideo();
    },
    async watchClipboardData() {
      if (!this.clipboardInterval) {
        this.clipboardInterval = setInterval(async () => {
          try {
            this.clipboardText = this.pastedText || await navigator.clipboard.readText(); // prettier-ignore
            this.clipboardError = false;
            // eslint-disable-next-line no-empty
          } catch (e) {}
          const foundYoutubeId = this.getYoutubeVideoId(this.clipboardText);
          if (foundYoutubeId) this.clipboardYoutubeId = foundYoutubeId;
          else this.unsetClipboardItem();
        }, 1000);
      }
    },
    fetchClipboardItemInfo() {
      axios
        .get(
          `https://www.youtube.com/oembed?url=http://www.youtube.com/watch?v=${this.clipboardYoutubeId}&format=json` +
            this.clipboardYoutubeId +
            '?v=2&alt=jsonc'
        )
        .then((res) => {
          const { title, thumbnail_url } = res.data;
          this.setClipboardItem(title, thumbnail_url);
        });
    },
    setClipboardItem(title, artwork) {
      this.clipboardItem = {
        id: clipboardItemReservedId,
        title,
        artwork,
        timestamps: [],
        link: `https://www.youtube.com/watch?v=${this.clipboardYoutubeId}`,
        note: '',
      };
    },
    unsetClipboardItem() {
      this.clipboardItem = null;
    },
    async startDownload() {
      this.$modal.show('download-modal');
      const options = {
        method: 'GET',
        url: 'https://youtube-mp3-download1.p.rapidapi.com/dl',
        params: { id: this.getYoutubeVideoId(this.selectedItem.link) },
        headers: {
          'X-RapidAPI-Host': 'youtube-mp3-download1.p.rapidapi.com',
          'X-RapidAPI-Key':
            'l2OiVcu4sZmshYPIkMBhuTbWmUKip1tMxUpjsnh3dKfwaPc7QJ',
        },
      };
      const corruptLink = 'https://one.123berlin.xyz/mp3.php?url=';
      const result = await axios.request(options);
      this.$modal.hide('download-modal');
      if (
        result &&
        result.data &&
        result.data.link &&
        result.data.link !== corruptLink
      ) {
        const windowReference = window.open();
        windowReference.location = result.data.link;
      } else {
        // TEMP. fix - timeout to enable modal hiding before showing alert
        setTimeout(() => {
          alert(this.$t('downloadFailed'));
        }, 100);
      }
    },
    openFilter() {
      this.$modal.show('filter-modal');
      this.filterModel = { ...this.currentFilter };
    },
    submitFilter({ data }) {
      this.currentFilter = { ...data };
      this.$modal.hide('filter-modal');
    },
    clearFilter() {
      this.currentFilter = {};
      this.$modal.hide('filter-modal');
    },
    videoReady(event) {
      this.videoPlayer = event.target;
    },
    isClipboardItem(id) {
      return id === clipboardItemReservedId;
    },
    hasTimestamps(item) {
      return !!item.timestamps.length;
    },
    isSelected(id) {
      return this.selectedItem.id === id;
    },
    selectItem(id) {
      const item = this.items.find((item) => item.id === id) || {};
      this.selectedItem = {
        ...item,
        timestamps: JSON.parse(JSON.stringify([...(item.timestamps || [])])), // TEMP. fix: clone for dd-form
      };
    },
    unselectItem() {
      this.selectedItem = {};
    },
    openEdit() {
      this.$modal.show('edit-modal');
      setTimeout(() => {
        this.reorderTimestampZIndex();
      }, 1000);
    },
    closeEdit() {
      this.$modal.hide('edit-modal');
    },
    deleteItem() {
      deleteDoc(
        doc(
          getFirestore(),
          'users',
          this.profile.id,
          'library',
          this.selectedItem.id
        )
      ).then(() => {
        this.unselectItem();
        this.closeEdit();
      });
    },
    reorderTimestampZIndex() {
      document
        .querySelectorAll('.timestamp__wrapper .view__container--removable')
        .forEach((element, i, arr) => {
          element.style.zIndex = String(999 + arr.length - i);
        });
    },
    addItem(data) {
      delete data.id;
      addDoc(collection(getFirestore(), 'users', this.profile.id, 'library'), {
        ...data,
        created: new Date(),
        updated: new Date(),
      }).then(() => {
        this.clipboardText = '';
        this.unsetClipboardItem();
      });
    },
    updateItem({ data }) {
      delete data.id;
      updateDoc(
        doc(
          getFirestore(),
          'users',
          this.profile.id,
          'library',
          this.selectedItem.id
        ),
        { ...data, updated: new Date() }
      ).then(() => {
        this.selectItem(this.selectedItem.id);
        this.closeEdit();
      });
    },
    getItemWrapperWidth() {
      const element = document.getElementsByClassName('wrapper')[0];
      return element ? element.offsetWidth : 'auto';
    },
    getStampColor(timestamp) {
      const typeIndex = this.stampTypes.indexOf(timestamp.type);
      return stampColors[typeIndex + 1] || stampColors[0];
    },
  },
};
</script>
<style>
.text-button {
  @apply text-sm flex align-middle;
}

.vm--top-right-slot {
  top: 550px !important;
  left: 0 !important;
  text-align: center !important;
  z-index: 9999 !important;
}

.list-item {
  display: flex;
}
.list-enter-active {
  transition: all 0.2s;
}
.list-leave-active {
  opacity: 0;
}
.list-enter,
.list-leave-to {
  opacity: 0;
  transform: translateX(-30px);
}

/* === START: Form Styles === */

.dd-form .button--submit {
  display: none;
}

.button {
  font-weight: bold;
  font-size: 14px;
  text-transform: uppercase;
}

.button--submit {
  border-radius: 3px !important;
  margin-bottom: 0 !important;
  height: 50px !important;
  width: 460px !important;
  margin-top: 15px !important;
  width: 240px !important;
  font-weight: bold !important;
  font-size: 14px !important;
  margin-left: 0 !important;
  color: white !important;
  background: #c2052e !important;
  transition: 0.2s ease all;
  box-shadow: 0 20px 60px -2px rgb(27 33 58 / 40%);
}

.button--remove {
  visibility: visible !important;
  opacity: 1 !important;
  top: 36px !important;
}

.input-text,
.input-text__frame,
.button--add {
  height: 30px !important;
  font-size: 15px !important;
}

.button--add {
  width: 230px !important;
}

.button-remove {
  border-color: #2f2f2f !important;
  border-width: 1px !important;
}

.button-remove:hover {
  border-color: #202020 !important;
}

.input-text__description {
  margin-bottom: 2px !important;
}

.button--drag,
.button-drag,
.button-remove,
.button--remove {
  transition: none !important;
  color: #a7a7a7 !important;
}
.button--add {
  margin-top: 22px !important;
}

.button-add,
.button--add {
  color: #a7a7a7 !important;
  border-color: #1a1a1a !important;
  border-width: 2px !important;
  margin-bottom: 10px !important;
}
.button--add:hover {
  border-color: #a7a7a7 !important;
}

.view--leaf,
.view--branch {
  margin: 10px 0 !important;
}

.view--root {
  margin: 0 16px !important;
}

.timestamp .view--leaf {
  width: 110px !important;
}

.timestamp {
  margin: 0 !important;
}

.time {
  margin-right: 10px !important;
}

input[type='tel'] {
  height: 28px !important;
  max-height: 28px !important;
}

.headline__title {
  font-size: 24px !important;
  color: #a7a7a7 !important;
}

.vm--modal {
  overflow-y: scroll !important;
  overflow-x: hidden !important;
  top: 70px !important;
  left: 0px !important;
  width: 355px !important;
  margin: 0px auto !important;
  background: #202020 !important;
}

.vm--overlay {
  height: 100% !important;
  background: rgba(0, 0, 0, 0.65) !important;
}

.button--submit:hover {
  background-color: #a30527 !important;
}

.timestamp__wrapper {
  background: #2f2f2f !important;
}
.timestamp__wrapper .input-text {
  border: 2px solid #202020 !important;
}
.timestamp__wrapper .input-text:focus {
  border: 2px solid #a7a7a7;
}

.timestamp__wrapper .input-text__frame .input-text {
  border: 0px !important;
}

.input-tick__icon-wrapper {
  border: 1px solid #a7a7a7 !important;
  background-color: #202020 !important;
}

.input-tick__icon-wrapper--checked {
  border-color: #c2052e !important;
  background-color: #c2052e !important;
}

.input-tick__icon {
  fill: #202020 !important;
}

.input-tick__icon--checked {
  fill: #ffffff !important;
}

.input-checkbox__label {
  color: #ffffff !important;
}

.input-tick__headline {
  color: #a7a7a7 !important;
}

.button--drag {
  display: none !important;
}

.button--remove {
  right: -20px !important;
}

@media (min-width: 1024px) {
  .timestamp .view--leaf {
    width: 155px !important;
  }
  .button--add {
    width: 320px !important;
  }
}

@media (min-width: 1024px) {
  .vm--modal {
    width: 550px !important;
    /* height: 750px !important; */
  }
}

/* === END: Form Styles === */
</style>
