<template>
  <div id="app">
    <ScreenLibrary
      v-if="isSignedIn && library && isProfileLoaded && clipboardFetched"
      :items="library"
      :profile="profile"
      :clipboardAllowed="clipboardAllowed"
    />
    <ScreenAuth v-else-if="userFetched && !isSignedIn && !isSignInFinishLink" />
    <ScreenLoader v-else />
  </div>
</template>

<script>
import ScreenAuth from '@/components/ScreenAuth';
import ScreenLibrary from '@/components/ScreenLibrary';
import ScreenLoader from '@/components/ScreenLoader';
import {
  getFirestore,
  collection,
  doc,
  setDoc,
  onSnapshot,
  orderBy,
  query,
} from 'firebase/firestore';
import {
  getAuth,
  isSignInWithEmailLink,
  signInWithEmailLink,
} from 'firebase/auth';

export default {
  name: 'App',
  components: {
    // eslint-disable-next-line vue/no-unused-components
    ScreenAuth,
    ScreenLibrary,
    ScreenLoader,
  },
  watch: {
    userFetched() {
      if (this.isSignInFinishLink) {
        this.finishSignIn();
      }
    },
  },
  data() {
    return {
      user: null,
      profile: null,
      userFetched: false,
      library: [],
      clipboardAllowed: null,
      unsubscribe: {
        library: null,
        profile: null,
      },
    };
  },
  methods: {
    streamLibrary() {
      this.unsubscribe.library = onSnapshot(
        query(
          collection(getFirestore(), 'users', this.user.email, 'library'),
          orderBy('created', 'desc')
        ),
        (query) => {
          this.library = query.docs.map((doc) => {
            return { ...doc.data(), id: doc.id };
          });
        }
      );
    },
    streamProfile() {
      this.unsubscribe.profile = onSnapshot(
        doc(getFirestore(), 'users', this.user.email),
        (doc) => {
          if (doc.exists()) this.profile = { ...doc.data(), id: doc.id };
          else this.createProfile();
        }
      );
    },
    createProfile() {
      setDoc(doc(getFirestore(), 'users', this.user.email), {
        uid: this.user.uid,
        created: new Date(),
      });
    },
    initAuth() {
      getAuth().onAuthStateChanged((user) => {
        this.user = user;
        this.userFetched = true;
        if (user) {
          this.streamLibrary();
          this.streamProfile();
        } else {
          this.unsubscribe.library();
          this.unsubscribe.profile();
        }
      });
    },
    finishSignIn() {
      const auth = getAuth();
      if (!this.user && isSignInWithEmailLink(auth, window.location.href)) {
        let email = window.localStorage.getItem('emailForSignIn');
        if (!email) {
          email = window.prompt(this.$t('provideLink'));
        }
        signInWithEmailLink(auth, email, window.location.href)
          .then(async () => {
            window.localStorage.removeItem('emailForSignIn');
            this.redirectHome();
          })
          .catch(() => {
            this.redirectHome();
          });
      }
    },
    async testClipboardAccess() {
      try {
        await navigator.clipboard.readText();
        this.clipboardAllowed = true;
      } catch (e) {
        this.clipboardAllowed = false;
      }
    },
    redirectHome() {
      location.href = `${location.protocol}//${location.host}`;
    },
    urlParam(param) {
      let urlParam = '';
      if (window.location.href.indexOf(param) > -1) {
        urlParam = this.urlVars()[param];
      }
      return urlParam;
    },
    urlVars() {
      const vars = {};
      window.location.href.replace(
        /[?&]+([^=&]+)=([^&]*)/gi,
        (m, key, value) => {
          vars[key] = value;
        }
      );
      return vars;
    },
  },
  computed: {
    clipboardFetched() {
      return this.clipboardAllowed !== null;
    },
    isSignedIn() {
      return !!this.user;
    },
    isProfileLoaded() {
      return this.profile && Object.keys(this.profile).length > 0;
    },
    isSignInFinishLink() {
      return this.urlParam('mode') === 'signIn';
    },
  },
  created() {
    this.testClipboardAccess();
    this.initAuth();
  },
};
</script>

<style lang="scss">
@import url('https://fonts.googleapis.com/css?family=Public+Sans');
#app {
  font-family: Public Sans, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: white;
  min-height: 100vh;
  min-height: -webkit-fill-available;
  @apply max-w-lg m-auto w-full;
}
.screen-height {
  height: 100vh;
  max-height: -webkit-fill-available;
}

body {
  min-height: 100vh;
  min-height: -webkit-fill-available;
  background: #090909;
}

html {
  height: -webkit-fill-available;
}
</style>
