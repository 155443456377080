<template>
  <div>
    <!-- Options button -->
    <p
      class="text-button cursor-pointer items-center text-xl pl-7 select-none font-bold"
      @click="toggleItemOptions"
    >
      {{ menuSymbol }}
    </p>

    <!-- Options dropdown -->
    <div class="relative">
      <div
        v-if="optionsOpen"
        class="absolute mt-1 shadow-md z-10 right-0"
        style="min-width: 130px; background: #2f2f2f"
      >
        <div
          v-for="option in options"
          :key="option.text"
          @click="
            () => {
              option.action();
              closeItemOptions();
            }
          "
          class="cursor-pointer hover:opacity-70 transition-all w-full px-5 py-2"
        >
          {{ option.text }}
        </div>
      </div>
    </div>

    <!-- Options helper overlay -->
    <div
      v-if="optionsOpen"
      @click="closeItemOptions()"
      class="absolute h-screen w-screen top-0 left-0"
    ></div>
  </div>
</template>
<script>
export default {
  props: ['options'],
  data() {
    return {
      optionsOpen: false,
      menuSymbol: '⋮',
    };
  },
  methods: {
    closeItemOptions() {
      this.optionsOpen = false;
    },
    toggleItemOptions() {
      this.optionsOpen = !this.optionsOpen;
    },
  },
};
</script>
