export default {
  en: {
    uncategorized: 'Uncatogorized',
    close: '❯',
    edit: 'Edit',
    save: 'Save',
    delete: 'Delete',
    download: 'Download',
    downloadFailed: 'Download failed – please refresh the page and try again',
    addFromClipboard: '📥 Add to library',
    addTimestamps: '🖍 Add timestamps',
    missingType: '(missing type)',
    pasteLinkHere: 'Paste YT link here',
    emptyLibrary: '👋<br />Welcome to your library',
    noResultsForFilter: '🔎<br />No items found for this filter selection',
    pasteLinkToBegin: 'Paste a YouTube link to begin',
    pasteLinkAbove: 'Paste a YouTube link in the field above to begin',
    limitOf: 'Limit of',
    enterEmailToBegin: 'Enter your email to begin',
    wasReachedContactUs:
      'items has been reached! Please contact us to learn more.',
    itemAlreadyInLibrary: 'This item is already in your library',
    pleaseWait: 'Please wait...',
    continueWithEmail: 'Continue with email',
    email: 'E-mail',
    password: 'Password',
    passwordWithNote: 'Password (min. 8 characters)',
    passwordConfirm: 'Confirm password',
    provideLink: 'Please provide your email for confirmation',
    logIn: 'Log in',
    signUp: 'Sign up',
    forgotPassword: 'Forgot password?',
    sendResetLink: 'Send reset link',
    pleaseFollowInstructions:
      'Please follow the instructions in the inbox to reset the password',
    sentToEmail: 'Sent to your email',
    youCanClose:
      'Sign in link was sent to your email.<br/>You can close this window now.',
    appEmoji: '🍩',
    logOut: 'Log out',
    item: 'item',
    items: 'items',
    filter: 'Filter',
    update: 'Update',
    clearFilter: 'Clear',
    showContaining: 'Only show items containing',
  },
};
